import React from "react"
import { graphql } from "gatsby"
import Layout from "./Layout"
import InsightsLandingHero from "../components/insights/landing-hero"
import InsightsLandingSliderRecents from "../components/insights/landing-recents-slider"
import InsightsLandingSlider from "../components/insights/landing-slider"
import { seoHead } from "../components/utils.js"

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      page(id: $id) {
        id
        title
        content
        uri
        seo {
          title
          metaDesc
          opengraphTitle
          opengraphDescription
          opengraphUrl
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphImage {
            mimeType
            mediaDetails {
              file
            }
          }
        }
      }

      categories(first: 100) {
        nodes {
          id
          databaseId
          name
          slug
          uri
        }
      }

      posts(first: 100) {
        nodes {
          id
          title
          uri
          date
          insights {
            contentType
            hideFromListings
          }
          featuredImage {
            node {
              altText
              mimeType
              mediaDetails {
                file
              }
            }
          }
          categories {
            nodes {
              databaseId
            }
          }
        }
      }
    }
  }
`
const PageTemplateInsightsParent = ({ data }) => {
  const page = data.wpgraphql.page
  const categories = data.wpgraphql.categories.nodes
  const posts = data.wpgraphql.posts.nodes

  return (
    <>
      <Layout>
        <InsightsLandingHero pagedata={page} />

        <InsightsLandingSliderRecents posts={posts} />

        {categories.map(category => {
          if (category.databaseId !== 1) {
            return (
              <InsightsLandingSlider
                key={category.id}
                posts={posts}
                podcasts={false}
                categoryname={category.name}
                categoryuri={category.slug}
                categoryid={category.databaseId}
              />
            )
          } else {
            return null
          }
        })}
      </Layout>
    </>
  )
}

export default PageTemplateInsightsParent

// Metadata in head from gatsby ^v4.19.0
// replaces helmet which wasn't generating the tags statically

export const Head = ({ data }) => {
  const page = data.wpgraphql.page
  const seoTags = seoHead(page.seo, page.uri)
  return seoTags
}
