import React from "react"
import { Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { AdvancedImage } from "@cloudinary/react"
import { Cloudinary } from "@cloudinary/url-gen"
import { fill } from "@cloudinary/url-gen/actions/resize"
import * as style from "./landing-slider.module.css"

const InsightsLandingSliderRecents = props => {
  const thePosts = props.posts

  const cld = new Cloudinary({
    cloud: {
      cloudName: process.env.GATSBY_CLOUDINARY_CLOUD,
    },
    url: {
      analytics: false,
    },
  })

  var counter = 0

  const swiperItems = thePosts.map(post => {
    var includeListings = true

    if (post.insights.hideFromListings === true) {
      includeListings = false
    }

    let iconUse = "url(/assets/img/icon_article.svg)"
    let contentType = "Article"

    switch (post.insights.contentType) {
      case "Podcast":
        iconUse = "url(/assets/img/icon_podcast.svg)"
        contentType = "Podcast"
        break
      case "Video":
        iconUse = "url(/assets/img/icon_video.svg)"
        contentType = "Video"
        break
      default:
        iconUse = "url(/assets/img/icon_article.svg)"
        contentType = "Article"
    }

    // include if category match and only display 10 in initial listings
    if (includeListings && counter < 10) {
      // date format
      const d = new Date(post.date)
      const theDate = new Intl.DateTimeFormat("en-GB", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      }).format(d)

      counter++

      let altText
      let imgUri

      if (post.featuredImage) {
        altText = post.featuredImage.node.altText
        imgUri = post.featuredImage.node.mediaDetails.file
      } else {
        altText = "insights featured image"
        imgUri = "industries-fintech_u8zzb1.webp"
      }

      var theImage = cld.image(`${process.env.GATSBY_API_FOLDER_URL}${imgUri}`)

      theImage.quality("70")
      theImage.resize(fill().width(600).height(400))
      theImage.format("auto")

      if (!post.insights.hideFromListings) {
        return (
          <SwiperSlide
            key={post.id}
            className="swiper-slide d-flex flex-column"
          >
            <div style={{ overflow: "hidden" }}>
              <Link to={post.uri}>
                <AdvancedImage
                  cldImg={theImage}
                  alt={altText}
                  className={`mw-100 zoomer`}
                />
              </Link>
            </div>
            <h5
              className={`mt-3 mb-3 lower color-light-grey ${style.podcast_icon}`}
              style={{ backgroundImage: iconUse }}
            >
              {contentType} &bull; {theDate}
            </h5>
            <Link to={post.uri}>
              <h3 className="h4 mb-4 flex-grow-1">{post.title}</h3>
            </Link>
          </SwiperSlide>
        )
      } else {
        return null
      }
    } else {
      return null
    }
  })

  return (
    <>
      <div className={`d-flex mb-5 ${style.hide_swiper_overflow}`}>
        <div className="flex-grow-1 white_bg z_index_top"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 mb-3 mb-lg-4">
              <h3 className="lower">Latest Insights</h3>
            </div>
            <div className="col-6 mb-3 mb-lg-4 d-none d-lg-flex justify-content-end align-items-center">
              <span
                className={`ani_grey_arrow right_aligned ${style.ani_grey_arrow_static}`}
              >
                &nbsp;
              </span>
            </div>
          </div>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={20}
            breakpoints={{
              767: {
                slidesPerView: 2.2,
                spaceBetween: 20,
              },
              991: {
                slidesPerView: 2.5,
                spaceBetween: 30,
              },
            }}
            className={style.insights_1_swiper}
          >
            {swiperItems}
          </Swiper>
        </div>
        <div className="flex-grow-1"></div>
      </div>
    </>
  )
}

export default InsightsLandingSliderRecents
