import React from "react"
import { isSafariLess16 } from "../utils.js"

const InsightsLandingHero = () => {
  const isSafariV16 = isSafariLess16()

  return (
    <div className="hero-small d-flex align-items-center darkgrey_bg mb-100">
      <div className="container">
        <div id="hide-row-when-nav-open" className="row">
          <div className="col-12 color-white animated-headline">
            <span className="h4 mb-2 d-block header-fade-in">INSIGHTS</span>
            <div className="animated-headline-wrap">
              <h1 className="new_style">
                <span
                  className={`new_style_addit ${
                    isSafariV16 ? "safari_16" : ""
                  }`}
                >
                  Thought-provoking ideas from our thinkers
                </span>
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InsightsLandingHero
